$grid: (
    type: grid,
    default: default,
    generateGridClassesFromBreakpoints: true,
    columns: 12,
    column-gap: 1rem,
    row-gap: 1.5rem
);
$breakpoints: (
    desktop: 64rem,
    dividedteaser: 48em,
    history: 52em,
    history-lg: 74.3em,
    screenSmall: 767px
);
            @import "STYLE_UTILS/_base";.sw-accordion {
  overflow: hidden;
  padding: rem(20px 0);
}

/* stylelint-disable declaration-no-important */
.mobile-only {
  @include media('>=desktop') {
    display: none !important;
  }
}

.desktop-only {
  @include media('<desktop') {
    display: none !important;
  }
}

/* stylelint-enable declaration-no-important */

// Default Layout
.cmp-accordion:last-child {
  border-bottom: 1px solid map-get($borders, dark-border);
}

.cmp-accordion__title {
  display: block;
  font-weight: map-get($font-weights, bold);

  @include media('>=desktop') {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.sw-accordion__title {
  display: flex;
  flex-flow: row-reverse;
  justify-content: space-between;

  @include media('>=desktop') {
    align-items: flex-end;
    flex-flow: row;
  }
}

.cmp-accordion__icon {
  border-radius: 100%;
  height: rem(26px);
  width: rem(26px);

  .sw-icon {
    height: rem(26px);
    width: rem(26px);
  }
}

.sw-accordion__icon--collapse,
.sw-accordion__icon--expand:hover {
  background-color: map-get($backgrounds, dark);
  color: map-get($colors, dark-text);
  stroke: map-get($colors, light-text);
}

.cmp-accordion__button {
  background-color: map-get($backgrounds, light-bg);
  border: none;
  border-top: 1px solid map-get($borders, dark-border);
  padding: rem(16px);

  .sw-accordion__icon--expand {
    display: block;
  }

  .sw-accordion__icon--collapse {
    display: none;
  }

  @include media('>=desktop') {
    padding: rem(20px);
  }
}

.cmp-accordion__button--expanded {
  background-color: map-get($backgrounds, light-bg);
  border: none;
  border-top: 3px solid map-get($borders, dark-border);
  padding: rem(16px);

  .sw-accordion__icon--expand {
    display: none;
  }

  .sw-accordion__icon--collapse {
    display: block;
  }

  @include media('>=desktop') {
    padding: rem(20px);
  }
}

.cmp-accordion__panel--expanded {
  margin-left: rem(16px);
  margin-bottom: rem(20px);

  @include media('>=desktop') {
    margin: rem(0 48px 20px);
  }
}

// Icon Layout Only
.iconlayout {
  .cmp-accordion__title {
    font-weight: map-get($font-weights, light);
  }

  .sw-accordion__title {
    @include media('<desktop') {
      display: block;
    }
  }

  .sw-accordion__title-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > .icon > .sw-icon {
      height: rem(72px);
      width: rem(72px);
      margin: rem(0 20px 0 0);
    }

    @include media('<desktop') {
      margin-bottom: rem(20px);
    }
  }

  .cmp-accordion__button .text,
  .cmp-accordion__button--expanded .text {
    font-size: rem(16px);
    margin-top: 1rem;
    width: calc(100% - #{map-get($grid, column-gap) * 2});
  }
}
